:root {
  --light-green: #299;
  --dark-green: #277;
}

* {
  font-family: 'Jura', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#blah {
  background: #277 !important;
}